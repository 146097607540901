.vuiAppLayout {
  display: flex;
  flex-direction: row;
  padding-top: $appHeaderHeight;
  height: 100vh;
}

.vuiAppLayout--full {
  padding-top: 0;
}

.vuiAppLayout__sideNav {
  flex-shrink: 0;
  overflow-y: auto;
}

.vuiAppLayout__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
