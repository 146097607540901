.vuiAccountMenu {
  min-width: 260px;
}

.vuiAccounrMenuHeader {
  padding: $sizeM;
  border-bottom: 1px solid $borderColor;
}

.vuiAccountMenuHeaderItem__title {
  font-size: $fontSizeSmall;
  font-weight: $fontWeightBold;
  color: $colorDarkerShade;
}

.vuiAccountMenuHeaderItem__value {
  font-size: $fontSizeStandard;
  color: $colorDarkerShade;
  margin-top: $sizeXxs;
}
